@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700;800&display=swap');
/*
Solutions
*/

.ColSolutions{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.workingImg{
    width: 250px;
    float: none;
    margin-top: 20px;
}

.WorkPhrase{
    font-family: 'Nunito Sans', sans-serif;
    font-size: 1.1rem;
    font-weight: 600;
    color: #7c7b7b;
    text-align: center;
}