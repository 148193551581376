@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700;800&display=swap');
/*
Footer
*/

.footer {
    background-color: #474747;
    font-family: 'Nunito Sans', sans-serif;
    padding: 10px;
    right: 0;
    left: 0;
    bottom: 0;
    margin-top: auto;
  }
  
  .footer a{
    color: white;
    font-size: 17px;
  }
  
  .footer a:hover{
    color: gray;
  }
  
  .footer .txt{
    margin-top: 15px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
  }