@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700;800&display=swap');
/*
Explicación 2
*/

.exp2 {
  font-family: 'Nunito Sans', sans-serif;
  margin-top: 50px;
}

.termView2 {
  width: 900px;
  float: right;
  margin-bottom: 30px;
  border-radius: 30px;
}

.termViewmd2 {
  width: 650px;
  float: right;
  margin-bottom: 30px;
  border-radius: 30px;
}

.termViewxs2 {
  width: 350px;
  float: none;
  margin-bottom: 30px;
  border-radius: 30px;
}

.organize2{
  align-items: center;
}

.headerExp2{
  text-align: center;
  font-size: 35x;
  font-weight: 800;
  margin-bottom: 15px;
  color: gray;
 }

 .contentExp2{
  background-color: #ededf3;
  border-radius: 20px;
 }

 .textExp12{
  color: gray;
  font-weight: 900;
  text-align: left;
  font-size: 20px;
  margin-top: 30px;
  margin-left: 30px;
  margin-bottom: 15px;
 }

 .colImg2{
  display: flex;
  justify-content: center;
  margin-top: 10px;
  align-items: center;
 }

 .textExp22{
  color: #2B0B98;
  font-weight: 600;
  text-align: left;
  font-size: 15px;
  margin: 15px;
  margin-left: 30px;
 }