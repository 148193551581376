@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700;800&display=swap');

/*
Diseño general
*/
html, body{
  height: 100%;
  margin: 0;
}

body {
  margin: 0;
  font-family: 'Nunito Sans', sans-serif;
}

.BodyContent {
  position: relative;
  margin: 0;
  min-height: 87hv;
}

.App{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
  position: relative;
  margin: 0;
  background-color: whitesmoke;
}

.mainCont {
  flex: 1;
  margin-top: 10px;
  margin-bottom: 10px;
}

/*
Prices Page
*/

.planIdeal{
  font-size: bold;
  font-weight: 800;
  text-align: center;
  margin-bottom: 30px;
}

.mejorPlan{
  text-align: center;
  margin-bottom: 30px;
  font-size: 22px;
  color: gray;
}

/*
Solutions Page
*/

.SolTitle {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 3.5rem;
  font-weight: 800;
  color: #1511AA;
  margin-bottom: 1rem;
  text-align: center;
  margin-top: 10px;
}

.SolPhrase{
    font-family: 'Nunito Sans', sans-serif;
    font-size: 1rem;
    font-weight: 600;
    color: #000000;
    margin-bottom: 1rem;
    text-align: center;
}

.SolBottomPhrase{
  font-family: 'Nunito Sans', sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  color: #1511AA;
  margin-bottom: 40px;
  text-align: center;
  margin-top: 20px;
}

.workInitial{
  width: 350px;
  float: none;
  border-radius: 20px;
  margin-top: 20px
}

.initImgPhrase{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

/*
About Us Page
*/

.ProductTitle {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 3.5rem;
  font-weight: 800;
  color: black;
  margin-bottom: 1rem;
  text-align: center;
  margin-top: 10px;
}

/*
Help Page
*/
.FAQTitle{
  font-family: 'Nunito Sans', sans-serif;
  font-size: 3.5rem;
  font-weight: 800;
  color: black;
  margin-bottom: 1rem;
  text-align: center;
  margin-top: 10px;
}