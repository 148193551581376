@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700;800&display=swap');
/*
Frase y botón
*/

  .secFrase {
    font-family: 'Nunito Sans', sans-serif;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  
  .organize3{
    align-items: center;
  }
  
  .headerExp3{
    text-align: center;
    font-size: 50x;
    font-weight: 800;
    margin-bottom: 15px;
   }
  
   .contentExp3{
    background-color: #ededf3;
    border-radius: 20px;
   }
  
   .textExp13{
    color: gray;
    font-weight: 900;
    text-align: left;
    font-size: 20px;
    margin-top: 30px;
    margin-left: 30px;
    margin-bottom: 15px;
   }
  
   .colImg3{
    display: flex;
    justify-content: center;
    margin-top: 10px;
    align-items: center;
   }
  
   .textExp23{
    color: #2B0B98;
    font-weight: 600;
    text-align: left;
    font-size: 15px;
    margin: 15px;
    margin-left: 30px;
   }

   .textExp33{
    color: #2B0B98;
    font-weight: 900;
    text-align: center;
    font-size: 20px;
    margin-bottom: 50px;
    line-height: 1.2;
   }