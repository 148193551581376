@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700;800&display=swap');
/*
Explicación 1
*/
  .exp{
    font-family: 'Nunito Sans', sans-serif;
    margin-top: 60px;
  }
 
  .cellView {
    width: 450px;
    float: none;
    margin-bottom: 30px;
    border-radius: 20px;
  }

  .termView {
    width: 450px;
    float: right;
    margin-bottom: 30px;
    border-radius: 20px;
  }

  .cellViewmd {
    width: 300px;
    float: none;
    margin-bottom: 30px;
    border-radius: 20px;
  }

  .termViewmd {
    width: 300px;
    float: right;
    margin-bottom: 30px;
    border-radius: 20px;
  }

  .cellViewxs {
    width: 250px;
    float: center;
    margin-bottom: 30px;
    border-radius: 20px;
  }

  .termViewxs {
    width: 250px;
    float: none;
    margin-bottom: 30px;
    border-radius: 20px;
  }
  .organize{
    align-items: center;
  }
  
  .headerExp{
    text-align: center;
    font-size: 50px;
    font-weight: 800;
    margin-bottom: 15px;
   }

   .contentExp{
    background-color: #ededf3;
    border-radius: 20px;
   }

   .textExp{
    color: #2B0B98;
    font-weight: 900;
    text-align: center;
    font-size: 20px;
    margin: 30px;
   }

   .colImg{
    display: flex;
    justify-content: center;
    margin-top: 30px;
    align-items: center;
   }

   .textExp2{
    color: black;
    font-weight: 900;
    text-align: center;
    font-size: 20px;
    margin: 30px;
   }