@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700;800&display=swap');
/*
Product Explanation
*/

.ProductExpContainer {
    margin-bottom: 50px;
}

.ProductCard {
    width: 20rem;
    text-align: left;
    box-shadow: 5px 10px 9px 3px rgba(0, 0, 0, 0.2);
    background-color: #ededf3;
    border-radius: 20px;
    margin-bottom: 30px;
    margin-top: 30px;
    border-color: transparent;
    position: relative;
}

.ProductCardInside {
    width: 100%;
    text-align: left;
    background-color: white;
    border-radius: 20px;
    float: right;
    border-color: transparent;
    bottom: -20px;
    right: -20px;
    margin-top: -16px;
    height: 52%;
}

.ProductCardInsideCheck {
    width: 100%;
    text-align: left;
    background-color: white;
    border-radius: 20px;
    float: right;
    border-color: transparent;
    margin-top: -16px;
    box-shadow: 3px 10px 9px 3px rgba(147, 146, 146, 0.2);
    height: 52%;
}

.ProdCardRow {
    display: flex;
    justify-content: center;
}

.ProdCardColumn {
    display: flex;
    justify-content: center;
}

.ProductCardTitle {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 1.5rem;
    font-weight: 800;
    color: #1511AA;
}

.ProductCardText {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 0.7rem;
}

.BulbImg {
    width: 60px;
    height: 60px;
    align-items: center;
    align-content: center;
}

.ProdCardImg {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    align-items: center;
}

.ProductCardImg {
    width: 20rem;
    text-align: left;
    box-shadow: 3px 10px 9px 3px rgba(0, 0, 0, 0.2);
    background-color: white;
    border-radius: 20px;
    margin-bottom: 30px;
    margin-top: 30px;
    border-color: transparent;
    position: relative;
}

.ProductCardTextInside {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 0.7rem;
    color: black;
    margin-bottom: -20px;
}

.custom-bullets {
    list-style-type: none;
    padding-left: 0;
}

.custom-bullets li {
    position: relative;
    padding-left: 25px;
    margin-bottom: 10px;
}

.switch li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background-image: url('./../../img/Switch.png');
    background-size: contain;
    background-repeat: no-repeat;
}

.star li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 20%;
    transform: translateX(80%);
    width: 10px;
    height: 10px;
    background-image: url('./../../img/Star.png');
    background-size: contain;
    background-repeat: no-repeat;
}

.check li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 20%;
    transform: translateX(80%);
    width: 10px;
    height: 10px;
    background-image: url('./../../img/Check.png');
    background-size: contain;
    background-repeat: no-repeat;
}