@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700;800&display=swap');
/*
Prices
*/

.precios .card {
    background-color: white;
    width: 18rem;
    text-align: center;
    box-shadow: 5px 10px 9px 3px rgba(0, 0, 0, 0.2);
    border-color: white;
    border-radius: 20px;
    height: 500px;
}

.titulo{
    color: #1511FE;
    font-size: 25px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 20px;
}

.precios{
    margin-bottom: 50px;
}

.fila{
    line-height: normal;
    font-weight: 700;
    text-align: left;
    padding-left: 0.3rem;
}

li::marker{
    color: #1511FE;
}

.fila li{
    margin-top: 25px;
}

.botonPrecios:active {
    background-color: #2B0B98;
  }

.botonPrecios{
    background-color: #1511FE;
    font-weight: 800;
    font-family: 'Nunito Sans', sans-serif;
    color: white;
    border-radius: 50px;
    border-color:#1511FE;
    border-width: 0.15cap;
    width: 200px;
    height: 30px;
    box-shadow: 0px 10px 5px 0px rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
  }

  .botonPreciosProbar{
    background-color: white;
    font-weight: 800;
    font-family: 'Nunito Sans', sans-serif;
    color: black;
    border-radius: 50px;
    border-color:#1511FE;
    border-width: 0.15cap;
    width: 200px;
    height: 30px;
    box-shadow: 0px 10px 5px 0px rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
}

.botonPreciosProbar:active {
    background-color: #cccccc;
  }

.debajo{
    color:#6B6B6B;
}

.overCard{
    color: #1511FE;
    font-size: 25px;
    text-align: center;
    font-weight: 800;
}

.rowCards{
    display: flex;
    justify-content: center;
}

.textDetail{
    text-align: center;
    font-size: 16px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: gray;
}

.colSup{
    margin-top: 20px;
}