@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700;800&display=swap');
/*
Header
*/
.App-header {
    background-color: white;
    font-family: 'Nunito Sans', sans-serif;
  }

  .App-logo{
    height: 80px;
  }
  
  .boton{
    background-color: #1FFCF5;
    font-weight: bold;
    font-family: 'Nunito Sans', sans-serif;
    color: gray;
    width: 150px;
    height: 35px;
    border-radius: 20px;
    font-size: 17px;
    border: none;
  }

  .boton2{
    background-color: white;
    font-weight: bold;
    font-family: 'Nunito Sans', sans-serif;
    color: gray;
    width: 150px;
    height: 35px;
    border-radius: 20px;
    font-size: 17px;
    border-color: #c4bcc4;
    border-width: 1px;
  }

  .navigWords{
    font-weight: 900;
    color: gray;
    text-align: center;
    font-size: 20px;
    margin: 5px 30px;
    text-decoration-line: none;
  }

  .underline-link {
    position: relative;
}

.underline-link::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: black;
  bottom: -2px;
  left: 0;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.underline-link:hover::after {
  transform: scaleX(1);
}

.resNavBar{
  border-top: 1px solid #c4bcc4;
}

.bot{
  text-align: center;
}